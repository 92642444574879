import moment from 'moment';
import { bool } from 'prop-types';
import React, { useState } from 'react';
import { Chart } from 'react-google-charts';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { BrandingIconCard, Button, IconSpinner, Modal, NamedLink, PaginationLinks } from '../../components';
import LayoutSingleColumn from '../../components/LayoutComposer/LayoutSingleColumn/LayoutSingleColumn';
import Page from '../../components/Page/Page';
import { useConfiguration } from '../../context/configurationContext';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled } from '../../ducks/ui.duck';
import { isBookingProcess, resolveLatestProcessName } from '../../transactions/transaction';
import { transitions as dealTransitions } from '../../transactions/transactionProcessDeal';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { InboxItem } from '../InboxPage/InboxPage';
import { getStateData } from '../InboxPage/InboxPage.stateData';
import { getOwnListingsById } from '../ManageListingsPage/ManageListingsPage.duck';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import css from './DashboardPage.module.css';

export const data = [
  ['Pizza', 'Popularity'],
  ['Pizza', 10],
  ['Pepperoni', 45],
  ['Hawaiian', 45],
];
export const databar = [
  ['Year', ''],
  ['2014', 200],
  ['2015', 250],
  ['2016', 300],
  ['2017', 350],
];
export const dataLines = [
  ['Year', 'Sales', 'Expenses'],
  ['2013', 1000, 400],
  ['2014', 1170, 460],
  ['2015', 660, 1120],
  ['2016', 1030, 540],
];
const BrandingIconTypes = {
  DEALS: 'deals',
  SOLD: 'sold',
  TRENDUP: 'trentup',
  TOTAL: 'total',
};

const badgeIcon = {
  BUCKET: 'bucket',
  BUZZ: 'buzz',
  SCANQR: 'scanqr',
  ACCEPTCODE: 'acceptcode',
};

const DashboardPageComponent = props => {
  const config = useConfiguration();

  const {
    currentUser,
    fetchInProgress,
    fetchOrdersOrSalesError,
    intl,
    pagination,
    params,
    providerNotificationCount,
    scrollingDisabled,
    transactions,
    appState,
    listings = [],
  } = props;

  // Find Total Deals
  const totalDeals = listings.length;

  // Find Active Deals
  const activeDeals = listings.filter(deal => {
    const { deal_end_date } = deal.attributes.publicData || {};
    return moment.unix(deal_end_date).isAfter(moment());
  });


  const draftId = '00000000-0000-0000-0000-000000000000';
  const draftSlug = 'draft';

  const [isModalOpen, setIsModalOpen] = useState(true);

  const {
    userType,
    isProductServiceListingPublished,
    isDealListingPublished,
    isBusinessListingPublished,
  } = currentUser?.attributes?.profile?.publicData || {};

  const isOnboardingComplete =
    isProductServiceListingPublished && isDealListingPublished && isBusinessListingPublished;

  const hasNoResults = !fetchInProgress && transactions.length === 0 && !fetchOrdersOrSalesError;

  const date = new Date();
  const options = { year: 'numeric', month: 'short', day: '2-digit' };
  const formattedDate = date.toLocaleDateString('en-US', options);

  // Filter Today's transactions
  const filteredTransactions = transactions.filter(transaction => {
    const lastTransitionDateStr = transaction?.attributes?.lastTransitionedAt;

    if (lastTransitionDateStr) {
      const lastTransitionDate = new Date(lastTransitionDateStr);
      const formattedLastTransitionDate = lastTransitionDate.toLocaleDateString('en-US', options);
      return formattedLastTransitionDate === formattedDate;
    }
    return false;
  });

  // Calculate Today's sales in cents
  const soldToday = filteredTransactions.reduce((total, transaction) => {
    const saleAmount = transaction?.attributes?.payinTotal?.amount || 0;
    return total + saleAmount;
  }, 0);

  // Calculate Total sales in cents
  const totalSales = transactions.reduce((total, transaction) => {
    const saleAmount = transaction?.attributes?.payinTotal?.amount || 0;
    return total + saleAmount;
  }, 0);


  const pickType = lt => conf => conf.listingType === lt;
  const findListingTypeConfig = publicData => {
    const listingTypeConfigs = config.listing?.listingTypes;
    const { listingType } = publicData || {};
    const foundConfig = listingTypeConfigs?.find(pickType(listingType));
    return foundConfig;
  };

  const hasOrderOrSaleTransactions = (tx, user) => {
    return user?.id && tx && tx.length > 0 && tx[0].provider.id.uuid === user?.id?.uuid;
  };

  const hasTransactions = !fetchInProgress && hasOrderOrSaleTransactions(transactions, currentUser);

  const toTxItem = tx => {
    const transactionRole = 'provider';
    let stateData = null;
    try {
      stateData = getStateData({ transaction: tx, transactionRole, intl });
    } catch (error) {
      // If stateData is missing, omit the transaction from InboxItem list.
    }

    const publicData = tx?.listing?.attributes?.publicData || {};
    const foundListingTypeConfig = findListingTypeConfig(publicData);
    const { transactionType, stockType } = foundListingTypeConfig || {};
    const process = tx?.attributes?.processName || transactionType?.transactionType;
    const transactionProcess = resolveLatestProcessName(process);
    const isBooking = isBookingProcess(transactionProcess);

    // Render InboxItem only if the latest transition of the transaction is handled in the `txState` function.
    return stateData ? (
      <li key={tx.id.uuid} className={css.listItem}>
        <InboxItem
          transactionRole={transactionRole}
          tx={tx}
          intl={intl}
          stateData={stateData}
          stockType={stockType}
          isBooking={isBooking}
        />
      </li>
    ) : null;
  };

  return (
    <Page title={''} scrollingDisabled={scrollingDisabled} referrer="origin">
      <LayoutSingleColumn
        className={css.mainLayout}
        mainColumnClassName={css.layoutWrapperMain}
        topbar={<TopbarContainer />}
      >
        <div className={css.dashboardWrapper}>
          <div className={css.dashboardHeader}>
            <div className={css.dashboardHeading}>
              Hello {currentUser?.attributes?.profile?.firstName},
            </div>
            <div className={css.dashboardSubHeading}>
              Here’s a summary of your business performance
            </div>
          </div>
          <div className={css.dashboardBody}>
            <div className={css.dealsCardsWrapper}>
              <div className={css.dealCard}>
                <div className={css.cardLeft}>
                  <div className={css.number}>{activeDeals.length}</div>
                  <div className={css.heading}>
                    <FormattedMessage id="DashboardPage.activeDeals" />
                  </div>
                </div>
                <div className={css.cardRight}>
                  <BrandingIconCard type={BrandingIconTypes.DEALS} />
                </div>
              </div>
              <div className={css.dealCard}>
                <div className={css.cardLeft}>
                  <div className={css.number}>{soldToday ? soldToday / 100 : 0}</div>
                  <div className={css.heading}>
                    <FormattedMessage id="DashboardPage.soldToday" />
                  </div>
                </div>
                <div className={css.cardRight}>
                  <BrandingIconCard type={BrandingIconTypes.SOLD} />
                </div>
              </div>
              <div className={css.dealCard}>
                <div className={css.cardLeft}>
                  <div className={css.number}>{totalDeals}</div>
                  <div className={css.heading}>
                    <FormattedMessage id="DashboardPage.totalDeals" />
                  </div>
                </div>
                <div className={css.cardRight}>
                  <BrandingIconCard type={BrandingIconTypes.TRENDUP} />
                </div>
              </div>
              <div className={css.dealCard}>
                <div className={css.cardLeft}>
                  <div className={css.number}>{totalSales ? totalSales / 100 : 0}</div>
                  <div className={css.heading}>
                    <FormattedMessage id="DashboardPage.totalSales" />
                  </div>
                </div>
                <div className={css.cardRight}>
                  <BrandingIconCard type={BrandingIconTypes.TOTAL} />
                </div>
              </div>
            </div>
            <div className={css.badgeCardBox}>
              <NamedLink className={css.link} name="DealsPage">
                <div className={css.badgeCard}>
                  <div>
                    <BrandingIconCard type={badgeIcon.BUCKET} />
                  </div>
                  <div className={css.heading}>{'Create Deal'}</div>
                </div>
              </NamedLink>
              <NamedLink className={css.link} name="SendBuzzPage">
                <div onClick={() => { }} className={css.badgeCard}>
                  <div>
                    <BrandingIconCard type={badgeIcon.BUZZ} />
                  </div>
                  <div className={css.heading}>{'Send A Buzz'}</div>
                </div>
              </NamedLink>
              <NamedLink className={css.link} name="CalenderPage">
                <div className={css.badgeCard}>
                  <div>
                    <BrandingIconCard type={badgeIcon.SCANQR} />
                  </div>
                  <div className={css.heading}>{'Scan QR'}</div>
                </div>
              </NamedLink>
              <NamedLink className={css.link} name="CalenderPage">
                <div className={css.badgeCard}>
                  <div>
                    <BrandingIconCard type={badgeIcon.ACCEPTCODE} />
                  </div>
                  <div className={css.heading}>{'Accept Code'}</div>
                </div>
              </NamedLink>
            </div>
            <div className={css.cartList}>
              <div className={css.ratioCard}>
                <div className={css.heading}>
                  <span>Fulfilment Ratio</span>
                  <span className={css.threeDot}>
                    <BrandingIconCard type="threedot" />
                  </span>
                </div>
                <div className={css.subHeading}>This Week</div>
                <div className={css.chartBox}>
                  <Chart
                    chartType="PieChart"
                    data={data}
                    // options={options}
                    width={'100%'}
                    height={'100%'}
                  />
                </div>
              </div>
              <div className={css.ratioCard}>
                <div className={css.heading}>
                  <span>Sales Volume</span>
                  <span className={css.threeDot}>
                    <BrandingIconCard type="threedot" />
                  </span>
                </div>
                <div className={css.chartBox}>
                  <Chart
                    chartType="Bar"
                    width="100%"
                    height="100%"
                    data={databar}
                  // options={options}
                  />
                </div>
              </div>
              <div className={css.ratioCard}>
                <div className={css.heading}>
                  <span>Total Revenue</span>
                  <span className={css.threeDot}>
                    <BrandingIconCard type="threedot" />
                  </span>
                </div>
                <div className={css.chartBox}>
                  <Chart
                    chartType="AreaChart"
                    width="100%"
                    height="100%"
                    data={data}
                  // options={options}
                  />
                </div>
              </div>
            </div>
            <div className={css.bookingOrder}>
              <div className={css.bookingHeader}>
                <div className={css.bookingHeading}>Latest Bookings & Orders</div>
                <div className={css.moreLink}>
                  <a href="#">
                    More
                    <BrandingIconCard type="rightarrow" />
                  </a>
                </div>
              </div>
              <div className={css.tableWrapper}>
                <ul className={css.itemList}>
                  <div className={css.bookingTable}>
                    <table>
                      <thead>
                        <tr>
                          <th>Image</th>
                          <th>Name </th>
                          <th>Title</th>
                          <th>Number of items:</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                  {!fetchInProgress ? (
                    transactions.map(toTxItem)
                  ) : (
                    <li className={css.listItemsLoading}>
                      <IconSpinner />
                    </li>
                  )}
                  {hasNoResults ? (
                    <li key="noResults" className={css.noResults}>
                      <FormattedMessage id={'InboxPage.noOrdersFound'} />
                    </li>
                  ) : null}
                </ul>
              </div>
              {hasTransactions && pagination && pagination.totalPages > 1 ? (
                <PaginationLinks
                  className={css.pagination}
                  pageName="LandingPage"
                  // pagePathParams={params}
                  pagination={pagination}
                />
              ) : null}
            </div>
          </div>

          {userType === 'provider' && !isOnboardingComplete ? (
            <Modal
              className={css.completeOnboardingModal}
              id="VerifyDeletingPaymentMethod"
              isOpen={isModalOpen}
              onClose={() => {
                setIsModalOpen(false);
              }}
              usePortal
              contentClassName={css.modalContent}
              onManageDisableScrolling={() => { }}
            >
              <div>
                <div className={css.completeOnboardingText}>
                  <FormattedMessage id="TopbarDesktop.completeOnboarding" />
                </div>
                <NamedLink name="MerchantOnboardingPage" className={css.topbarLink}>
                  <Button type="button">
                    <FormattedMessage id="TopbarDesktop.continueButtonText" />
                  </Button>
                </NamedLink>
              </div>
            </Modal>
          ) : null}
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};
DashboardPageComponent.defaultProps = {};
DashboardPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    fetchInProgress,
    fetchOrdersOrSalesError,
    pagination,
    transactionRefs,
    currentPageResultIds,
  } = state.LandingPage;

  const {
    // currentPageResultIds,
    queryInProgress,
    queryListingsError,
    queryParams,
    openingListing,
    openingListingError,
    closingListing,
    closingListingError,
  } = state.ViewListingPage;

  const { currentUser, currentUserNotificationCount: providerNotificationCount } = state.user;
  const listings = getOwnListingsById(state, currentPageResultIds);

  const allTransactions = getMarketplaceEntities(state, transactionRefs);

  const filteredTransactions = allTransactions.filter(tx =>
    !tx?.attributes?.transitions?.some(t =>
      t.transition === dealTransitions.CHILD_PRODUCT_REQUEST_PAYMENT ||
      t.transition === dealTransitions.CHILD_SERVICE_REQUEST_PAYMENT
    )
  );

  return {
    currentUser,
    fetchInProgress,
    fetchOrdersOrSalesError,
    pagination,
    providerNotificationCount,
    scrollingDisabled: isScrollingDisabled(state),
    transactions: filteredTransactions,
    appState: state,
    currentPageResultIds,
    queryInProgress,
    queryListingsError,
    queryParams,
    openingListing,
    openingListingError,
    closingListing,
    closingListingError,
    listings,
  };
};

const DashboardPage = compose(connect(mapStateToProps), injectIntl)(DashboardPageComponent);
export default DashboardPage;
