import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import css from './DealTypes.module.css';
import first from '../../../../assets/images/11.png';
import second from '../../../../assets/images/22.png';
import third from '../../../../assets/images/33.png';
import fourth from '../../../../assets/images/44.png';
import fifth from '../../../../assets/images/55.png';
import six from '../../../../assets/images/66.png';
import { ResponsiveImage } from '../../../../components';

const dealsDetail = [
    {
        title: "% Discounts",
        image: first,
    },
    {
        title: "Amount Discount",
        image: second,
    },
    {
        title: "Buy One, Get One Free",
        image: third,
    },
    {
        title: "Buy One, Get One Half Off",
        image: fourth,
    },
    {
        title: "Service Bundles",
        image: fifth,
    },
    {
        title: "Service Bundles",
        image: six,
    },
]

const DealTypes = () => {
    const columnCount = 3; // Set to 3 columns
    const columns = Array.from({ length: columnCount }, (_, i) =>
        dealsDetail.filter((_, index) => index % columnCount === i)
    );

    return (
        <div className={css.dealTypesWrapper}>
            <div className={css.dealTopRow}>
                <div className={css.dealHeading}>Top Deal Types</div>
                <div className={css.viewAllLink}>View All</div>
            </div>
            <div className={css.dealCardsWrapper}>
                {/* {columns.map((column, i) => (
                    <div key={i}>
                        {dealsDetail.map((item, i) => {
                            return (
                                <div className={css.cardBox} key={i}>
                                    <img src={item.image} />
                                </div>
                            )
                        })}
                    </div>
                ))} */}
                <div className={css.row}>
                    {columns.map((column, i) => (
                        <div className={css.column} key={i}>
                            {column.map((src, index) => (
                                <ResponsiveImage
                                    alt={`Image ${index}`}
                                    image={src.image}
                                    variants={"custom-image"}
                                    key={index}
                                    width={100}
                                />
                            ))}
                        </div>
                    ))}
                </div>
                <div className={css.mobileRow}>
                    {dealsDetail.map((item, i) => {
                        return (
                            <div key={i} className={css.detailImage}>
                                <ResponsiveImage
                                    alt={"Details Image"}
                                    image={item.image}
                                    variants={"custom-image"}
                                />
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className={css.signUp}>Do you own a service business? Sign Up on <span>NOW!</span></div>
        </div>
    )
};

DealTypes.defaultProps = {
    className: null,
    rootClassName: null,
};

DealTypes.propTypes = {
    className: string,
    rootClassName: string,
};

export default DealTypes;
