import { bool, func, shape, string } from 'prop-types';
import React from 'react';
import { intlShape } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import imageCalender from '../../../assets/images/Calendar.png';
import featureSection from '../../../assets/images/Features-Section.png';
import { LayoutSingleColumn, Page, ResponsiveImage } from '../../../components';
import FooterContainer from '../../FooterContainer/FooterContainer';
import { getMetadata } from '../../PageBuilder/PageBuilder';
import TopbarContainer from '../../TopbarContainer/TopbarContainer';
import BestDeals from './BestDeals/BestDeals';
import MerchantHero from './MerchantHero/MerchantHero';
import css from './MerchantHomePage.module.css';
import SlotsSection from './SlotsSection/SlotsSection';

export const MerchantHomePage = props => {
  const { intl, scrollingDisabled, pageAssetsData, merchantMaybe } = props;
  const meta = pageAssetsData?.meta ? { ...pageAssetsData.meta } : {};
  const pageMetaProps = getMetadata(meta);

  return (
    <Page {...pageMetaProps} scrollingDisabled={scrollingDisabled} referrer="origin">
      <LayoutSingleColumn
        className={css.merchantMainWrapper}
        mainColumnClassName={css.layoutWrapperMain}
        topbar={<TopbarContainer merchantMaybe={merchantMaybe} />}
        footer={<FooterContainer merchantPage />}
      >
        <div className={css.mainWrapper}>
          <div className={css.innerWrapper}>
            <MerchantHero />
            {/* <div className={css.calenderImage}>
              <ResponsiveImage
                alt={"Calender Image"}
                image={imageCalender}
                variants={"custom-image"}
              />
            </div> */}
            <iframe
             className={css.youtubeVideo}
              width="100%"
              height="665"
              src="https://www.youtube.com/embed/hqzvHfy-Ij0?si=IIP8PMq1WfidR-_B"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen>
            </iframe>

            <SlotsSection />
            <BestDeals />
            <div className={css.featureSection}>
              <ResponsiveImage
                alt={"feature Section Image"}
                image={featureSection}
                variants={"custom-image"}
              />
            </div>
          </div>
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

MerchantHomePage.defaultProps = {
  currentUser: null,
  verificationError: null,
};

MerchantHomePage.propTypes = {
  currentUser: propTypes.currentUser,
  scrollingDisabled: bool.isRequired,
  submitVerification: func.isRequired,
  isVerified: bool,
  emailVerificationInProgress: bool.isRequired,
  verificationError: propTypes.error,

  // from withRouter
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default MerchantHomePage;
