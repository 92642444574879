import { string } from 'prop-types';
import React from 'react';
import BrandingIconCard from '../../../../components/BrandingIconCard/BrandingIconCard';
import { convertToTitleCase } from '../../../../util/data';
import css from './PopularDealsCard.module.css';
import { NamedLink, ResponsiveImage } from '../../../../components';

const PopularDealsCard = ({ listings, updatedDealCategories }) => {
  const PERCENTAGE_DISCOUNT_TYPE = 'percentage-discount';
  return (
    <div className={css.popularDealsCardWrapper}>
      <div className={css.cardGrid}>
        {listings.map((item, i) => {
          const subcategory =
            updatedDealCategories
              ?.find(cat =>
                cat.subcategories.some(
                  sub => sub.id === item?.attributes?.publicData?.categoryLevel2
                )
              )
              ?.subcategories.find(
                sub => sub.id === item?.attributes?.publicData?.categoryLevel2
              ) || {};
          const subcategoryColor = subcategory.color || '#000';

          const details = item?.attributes || {};
          const { publicData, price } = details || {};

          const isPercentageDiscount = publicData?.categoryLevel2 === PERCENTAGE_DISCOUNT_TYPE;
          const percentage_discount = publicData?.percentage_discount ? publicData?.percentage_discount : publicData?.discount;

          const original_price = publicData?.original_price;
          const discounted_price = original_price && (original_price - price?.amount / 100)?.toFixed(2);

          return (
            <div className={css.dealWrapper}>
              <NamedLink
                name="EditListingPage"
                params={{ slug: details?.title, id: item?.id?.uuid, type: 'edit', tab: 'details' }}
              >
                <div key={i} className={css.card}>
                  <div className={css.cardImage}>
                    {isPercentageDiscount ? (
                      <span
                        style={{ backgroundColor: subcategoryColor }}
                        className={css.discountAmunt}
                      >
                        -{percentage_discount}%
                      </span>
                    ) : (
                      <span
                        style={{ backgroundColor: subcategoryColor }}
                        className={css.discountAmunt}
                      >
                        -
                        {original_price ? discounted_price : '0'}
                      </span>
                    )}
                    <ResponsiveImage
                      alt="card image"
                      image={item.images[0].attributes.variants['listing-card'].url}
                      className={css.cardImage}
                      variants={'custom-image'}
                    />
                    <span className={css.favIcon}>
                      <BrandingIconCard type="dislike" />
                    </span>
                  </div>
                  <div className={css.cardBody}>
                    <div className={css.bodyLeft}>
                      <h4>{item.attributes.title}</h4>
                      <div className={css.timeOffer}>
                        <span style={{ background: subcategoryColor }}>
                          {convertToTitleCase(
                            item?.attributes?.publicData?.categoryLevel2
                              ? item?.attributes?.publicData?.categoryLevel2
                              : item?.attributes?.publicData?.categoryLevel1
                          )}
                        </span>
                      </div>
                      <div className={css.servicesPrice}>
                        <span className={css.actualPrice}>
                          {original_price ? `${price?.currency} ${original_price}` : null}
                        </span>
                        <span className={css.discountPrice}>{`${price?.currency} ${price?.amount / 100}`}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </NamedLink>
            </div>
          );
        })}
      </div>
    </div>
  );
};

PopularDealsCard.defaultProps = {
  className: null,
  rootClassName: null,
};

PopularDealsCard.propTypes = {
  className: string,
  rootClassName: string,
};

export default PopularDealsCard;
